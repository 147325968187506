import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { StyledContainer } from "../components/core"
import HeaderParagraph from "../components/header-paragraph"
import Layout from "../components/layout-desktop"
import SEO from "../components/seo"

const supportedDevicesPage = () => {
  const data = useStaticQuery(graphql`
    query supportedDevices {
      markdownRemark(
        fileAbsolutePath: { regex: "/src/content/manufacturers/supported_devices/" }
      ) {
        htmlAst
        frontmatter {
          title
        }
      }
    }
  `)
  const markdownData = data.markdownRemark

  return (
    <Layout>
      <SEO
        title="Supported devices"
        description="Devices currently integrated with SixEye."
      />
      <StyledContainer text>
      <HeaderParagraph
          headerAs="h1"
          title={markdownData.frontmatter.title}
          htmlAst={markdownData.htmlAst}
        />
      </StyledContainer>

    </Layout>
  )
}
export default supportedDevicesPage;
